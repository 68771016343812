<template>
  <div class="basic-page">
    <a-spin :spinning="loading">
      <a-row
        type="flex"
        justify="space-between"
        style="padding: 0 20px"
        v-if="park"
      >
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">园区名称</div>
            <div class="info-content">{{ park.park_name }}</div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">所在位置</div>
            <div class="info-content">
              {{ park.park_address }}
              <span @click="goMap(park)">导航</span>
            </div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">联系电话</div>
            <div class="info-content">{{ park.park_phone }}</div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">占地面积</div>
            <div class="info-content">{{ park.park_floor_area }}㎡</div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">办公面积</div>
            <div class="info-content">{{ park.park_office_area }}㎡</div>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch
} from "@vue/runtime-core";
import { getParkData } from "@/api/main.js";
import { message } from "ant-design-vue";
import { getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import url from "@/utils/url.js";

export default defineComponent({
  name: "Basic",
  setup() {
    const pageData = reactive({
      park: null,
      loading: true,
      userData: computed(() => getUserData())
    });
    const getParkDataFun = () => {
      pageData.loading = true;
      getParkData({
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.park = res.data.park;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    // getParkDataFun();
    const goMap = _park => {
      url.navigateTo("/map", {
        t: encode(`${_park.park_location_lng},${_park.park_location_lat}`)
      });
    };
    watch(
      [() => pageData.userData.park_id],
      val => {
        getParkDataFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return { ...toRefs(pageData), goMap };
  }
});
</script>

<style lang="less" scoped>
.basic-page {
  .info {
    width: 540px;
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    .info-title {
      color: #212531;
      opacity: 0.7;
      font-size: 18px;
    }
    .info-content {
      color: #212531;
      font-size: 18px;
      span {
        width: 70px;
        height: 31px;
        background-color: #1276cb;
        border-radius: 15px;
        color: #ffffff;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
