<template>
  <div class="site-page">
    <a-spin :spinning="loading">
      <a-row type="flex" justify="space-between" v-if="site">
        <a-col v-for="(item, index) in site" :key="index">
          <div class="flex flex-between info" @click="goSiteDetail(item)">
            <div style="width: 30%">{{ item.site_name }}</div>
            <div style="width: 20%">{{ item.site_area }}㎡</div>
            <div style="width: 20%">{{ item.site_orientation }}</div>
            <div style="width: 30%; text-align: right">
              <span
                :class="{
                  'site-item': true,
                  'site-active': item.enterprise_site != null
                }"
                >{{ item.enterprise_site ? "已入驻" : "未入驻" }}</span
              ><RightOutlined />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch
} from "@vue/runtime-core";
import { getSiteList } from "@/api/main.js";
import { message } from "ant-design-vue";
import { getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import url from "@/utils/url.js";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Site",
  components: {
    RightOutlined
  },
  setup() {
    const pageData = reactive({
      site: null,
      loading: true,
      userData: computed(() => getUserData())
    });
    const getSiteListFun = () => {
      pageData.loading = true;
      getSiteList({
        park_id: pageData.userData?.user_park_id,
        page: 1,
        limit: 1000
      })
        .then(res => {
          if (res.code == 200) {
            pageData.site = res.data.site;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goSiteDetail = _item => {
      url.navigateTo("/park/site", {
        t: encode(`${_item.site_id}`)
      });
    };
    watch(
      [() => pageData.userData.park_id],
      val => {
        getSiteListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return { ...toRefs(pageData), goSiteDetail };
  }
});
</script>

<style lang="less">
.site-page {
  padding: 0 20px;
  box-sizing: border-box;
  .info {
    width: 540px;
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    font-size: 18px;
    color: #212531;
    cursor: pointer;
    .site-item {
      color: #212531;
      opacity: 0.6;
      margin-right: 20px;
    }
    .site-active {
      color: #1276cb;
    }
  }
}
</style>
