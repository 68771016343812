<template>
  <div class="park wrapper page-wrap">
    <div class="park-title">基本信息</div>
    <basic></basic>
    <div class="park-title">园区风采</div>
    <show></show>
    <div class="park-title">办公场地</div>
    <site></site>
    <div class="park-title">入驻企业</div>
    <enterprise></enterprise>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";
import Basic from "./components/basic.vue";
import Show from "./components/show.vue";
import Site from "./components/site.vue";
import Enterprise from "./components/enterprise.vue";
export default defineComponent({
  name: "Park",
  components: {
    Basic,
    Show,
    Site,
    Enterprise
    // RightOutlined
  },
  setup() {
    const store = useStore();

    const pageData = reactive({});
    return {};
  }
});
</script>
<style lang="less" scoped>
.park {
  .park-title {
    position: relative;
    margin-top: 50px;
    padding-left: 20px;
    font-size: 20px;
    color: #212531;
    margin-bottom: 20px;
    &:before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background-color: #1276cb;
      border-radius: 9px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
