<template>
  <div class="show-page">
    <a-spin :spinning="loading">
      <div class="flex show-box" v-if="park_show">
        <a-image-preview-group>
          <div class="img-item" v-for="(item, index) in park_show" :key="index">
            <a-image
              :width="210"
              :height="210"
              :src="item.show_src"
              class="park-img"
            />
            <!-- <div class="show-name">办公室</div> -->
            <div v-if="item.show_name" class="show-name">{{item.show_name}}</div>
          </div>
        </a-image-preview-group>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch
} from "@vue/runtime-core";
import { getParkShowList } from "@/api/main.js";
import { message } from "ant-design-vue";
import { getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";

export default defineComponent({
  name: "Show",
  setup() {
    const pageData = reactive({
      park_show: null,
      loading: true,
      userData: computed(() => getUserData())
    });
    const getParkShowListFun = () => {
      pageData.loading = true;
      getParkShowList({
        park_id: pageData.userData?.user_park_id,
        page:1,
        limit:30
      })
        .then(res => {
          if (res.code == 200) {
            pageData.park_show = res.data.park_show;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    watch(
      [() => pageData.userData.park_id],
      val => {
        getParkShowListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return { ...toRefs(pageData) };
  }
});
</script>

<style lang="less">
.show-page {
  box-sizing: border-box;
  padding: 0 20px;
  .show-box {
    flex-wrap: wrap;
    .img-item {
      margin-top: 20px;
      margin-left: 13.75px;
      margin-right: 13.75px;
      width: 210px;
      height: 210px;
      background-color: #1276cb;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5n+1){
          margin-left: 0;
      }
      .park-img {
        position: absolute;
        width: 210px;
        height: 210px;
      }
      .show-name {
        position: absolute;
        width: 210px;
        height: 35px;
        background-color: rgba(65,64,66,.5);
        left: 0;
        bottom: 0;
        color: #ffffff;
        text-align: center;
        line-height: 35px;
        font-size: 18px;
      }
    }
  }
}
</style>
